<template>
  <div>
    <div class="container">
      <div class="notice-box">
        <div class="notice-title">
          我的赛事
        </div>

        <!-- 选择比赛状态tabs -->

        <div class="work-year">
          <div v-for="s in statuslist" :key="s" class="year-href" @click="selectStatus(s)">
            <span :class="[statusactive==s ? 'year-active' : '']">
              {{ s }}
            </span>
          </div>

          <div class="info-box">
            <div v-for="(info, idx) in contestInfo" :key="idx">
              <div class="download_container">
                <!-- 下载报名表 布局 -->
                <div>
                  <el-row>
                    <el-col :span="15">
                      <div class="name">
                        {{ info.contestName }}
                      </div>
                      <div class="register-time">
                        报名截止时间：{{ info.registrationEndTime }}
                      </div>
                    </el-col>
                    <el-col :span="9">
                      <el-button
                        class="btn-download"
                        @click="checkEnrollInfo(info.id)"
                      >查看报名信息
                      </el-button>
                      <!-- 预览 按钮 -->
                      <el-button
                        class="btn-preview"
                        @click="checkEnrollInfo(info.id)"
                      >
                        <svg-icon name="filecheck" color="#ffffff" width="18" />
                      </el-button>
                    </el-col>
                  </el-row>

                </div>

                <!-- 右上角图标：有间隙 -->
                <div class="mask" />
                <div class="mask_content">
                  {{ statusactive }}
                </div>
              </div>

            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import '@/icons/filecheck'
import { fetchMyContestList } from '@/api/contest'

export default {
  data() {
    return {
      statuslist: ['进行中', '已结束'],
      statusactive: '进行中',

      userName: '',
      contestInfo: [],
      contestOn: null,
      contestEnd: null
    }
  },
  mounted() {
    this.userName = this.globalGetUsername()
    console.log('userName', this.userName)
    this.getMyContestList()
  },
  methods: {
    // 获取我的比赛列表界面
    getMyContestList() {
      fetchMyContestList(this.userName).then(res => {
        const contestlist = res.data
        if (contestlist.length === 0) {
          this.$message('您当前暂未参加任何比赛')
        }
        this.contestOn = contestlist.filter((item) => {
          return item.contestStatus === '报名中'
        })
        // console.log('报名中', this.contestOn)
        this.contestEnd = contestlist.filter((item) => {
          return item.contestStatus === '已结束'
        })
        // console.log('已结束', this.contestEnd)
        if (this.statusactive === '进行中') {
          this.contestInfo = this.contestOn
        } else {
          this.contestInfo = this.contestEnd
        }
      })
    },

    // 选择显示的比赛状态, 并且更新 contestInfo列表
    selectStatus(status) {
      this.statusactive = status
      if (this.statusactive === '进行中') {
        this.contestInfo = this.contestOn
      } else {
        this.contestInfo = this.contestEnd
      }
    },

    // 查看报名信息
    checkEnrollInfo(id) {
      // 分配测试 10；第一次 11
      this.$router.push('enrollinfo/' + id)
    }

  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.border {
  border: 1px solid red;
  height: 30px;
}

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 610px;
  width: 1000px;
  /* border: 1px solid red; */
}

.notice-title {
  text-align: center;
  margin-top: 10px;
  height: 80px;
  /* border: 1px solid red; */
  font-size: 20px;
  line-height: 80px;
}

.work-year {
  display: inline-block;
  width: 80%;
  margin: auto;
  text-align: left;
  /* width: 400px; */
}

.year-href {
  display: inline-block;
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-right: 15px;
  /* border: 1px solid red; */
  font-size: 16px;
  color: rgb(44, 43, 43);

}

.year-href:hover {
  cursor: pointer;
}

.year-active {
  display: inline-block;
  width: 100%;
  height: 100%;
  /* line-height: 30px; */
  color: var(--background-color);
  border-bottom: 2px solid var(--theme-color);
}

.info-box {
  width: 100%;
  height: 450px;
  overflow: auto;
}

/* 小框框 */
/* 下载报名表 容器 */
.download_container {
  width: 100%;
  height: 100px;
  display: inline-block;
  margin: auto;
  margin-top: 20px;
  border: 1px solid #595159;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

.name {
  padding-left: 40px;
  font-size: 16px;
  font-weight: 800;
}

.register-time {
  padding-left: 40px;
  font-size: 10px;
}

/* 下载 图标 */
.svg-icon-upload {
  vertical-align: bottom;
}

/* 上传报名表 按钮 */
.btn-upload {
  display: inline-block;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  border: none;
  /* 样式相关 */
  background: #6a5cb3;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-midline {
  display: inline-block;
  /* 设置div里面的文字无法被选中 */
  user-select: none;
  /* 定位相关 */
  vertical-align: bottom;
  height: 40px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: #5fdfc6;
  color: #fff;
  font-size: 14px;
  padding: 15px 15px;
}

/* 提示文字1 */
.tip_download1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_download2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

/* 右上角有间隙 样式 */
.mask {
  position: absolute;
  top: 11px;
  right: -4px;
  width: 32px;
  height: 5px;
  transform: rotate(45deg);
  border-width: 0px 2px 2px 2px;
  border-style: solid;
  border-color: transparent transparent var(--theme-color) transparent;
}

.mask_content {
  position: absolute;
  top: 16px;
  right: -16px;
  width: 80px;
  height: 20px;
  transform: rotate(45deg);
  border-width: 0px 20px 20px 20px;
  border-style: solid;
  border-color: transparent transparent var(--theme-color) transparent;
  color: white;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}

/* 上传报名表 容器 */
.upload_container {
  border: 1px solid #817481;
  border-radius: 5px;
  padding-top: 70px;
  padding-bottom: 30px;
  /*  用于 标签 定位 */
  position: relative;
  overflow: hidden;
}

/* 下载 图标 */
.svg-icon-download {
  transform: rotate(180deg);
  vertical-align: top;
}

/* 下载报名表 按钮 */
.btn-download {
  /* 定位相关 */
  vertical-align: top;
  height: 35px;
  width: 125px;
  border-radius: 8px 0 0 8px;
  margin-right: -10px;
  margin-left: 10px;
  border: none;
  /* 样式相关 */
  background: var(--background-color);
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  padding: 5px 20px;
}

/* 预览按钮 */
.btn-preview {
  /* 定位相关 */
  vertical-align: top;
  height: 35px;
  width: 35px;
  border: none;
  border-radius: 0 8px 8px 0;
  /* 样式相关 */
  background: var(--theme-color);
  color: #fff;
  font-size: 14px;
  padding: 5px 5px;
}

/* 提示文字1 */
.tip_upload1 {
  color: #7c7c7c;
  margin-top: 15px;
  cursor: pointer;
}

/* 提示文字2 */
.tip_upload2 {
  color: #7c7c7c;
  font-size: 6px;
  margin-top: 5px;
}

</style>
